<template>
  <div id="div_vote">
    <ul style="padding: 20px">
      <li>
        <h2>Welcome To WingsMS!</h2>
        <p>WingsMS致力于以大巨变前的游戏模式，拓宽怀旧内容的边界。让玩家能在熟悉的"小数值”，“单装备系统”的怀旧玩法基础上，体验跨版本的剧情与BOSS。</p>
      </li>
      <li id="vote_mask"><img src="@/assets/img/vote/star.png" alt=""></li>
    </ul>
    <ul>
      <li>
        <img src="@/assets/img/vote/vote.jpg" alt="">
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "comm_vote"
}
</script>

<style scoped>
li{
  list-style: none;
}
#div_vote{
  margin: 100px 0 20px 0;
  height: auto;
  background: #FFFFFF;
  border-radius: 24px;
  display: flex;
}
#div_vote li{
  /*display: block;*/
}
#div_vote li h2{
  margin-top: 40px;
  font-weight: 600;
  color: #000000;
}
#div_vote li p{
  margin-top: 20px;
  font-size: 14px;
  /*font-family: PingFang SC;*/
  font-weight: 500;
  color: #5C607E;
  line-height: 40px;
}
#div_vote li img{
  max-width: 100%;
}
#vote_mask{
  z-index: 1;
  position: relative;
  left: 70%;
  top:80px;
}
@media (max-width: 768px) {
  #div_vote{
    display: none;
  }
}
</style>