<template>
  <div id="menu_div">
    <h1>Welcome To WingsMS!</h1>
    <p>WingsMS致力于以大巨变前的游戏模式，拓宽怀旧内容的边界。让玩家能在熟悉的"小数值”，“单装备系统”的怀旧玩法基础上，体验跨版本的剧情与BOSS。</p>
    <ul>
      <supp_button button_link="https://www.wingstory.net/download">游戏下载</supp_button>
      <supp_button button_link="https://www.wingstory.net/support">支持我们</supp_button>
    </ul>
    <img src="@/assets/img/menu/zh.png" alt="" style="max-width: 100%; margin-top: 30px;">
  </div>
</template>

<script>
import supp_button from "@/components/common/supp_button";
export default {
  name: "menu_div",
  components:{
    supp_button
  }
}
</script>

<style scoped>

#menu_div {
  text-align: center;
  padding-top: 1px;
}
#menu_div h1 {
  font-weight: 900;
  color: #000000;
}
#menu_div p {
  font-size: 14px;
  font-weight: 500;
  color: #5C607E;
  line-height: 44px;
}
</style>