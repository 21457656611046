<template>
  <li class="supp_button">
    <a :href="button_link">
      <slot></slot>
    </a>
  </li>
</template>

<script>
export default {
  name: "supp_button",
  props: {
    button_link: 'button_link',
  }
}
</script>

<style scoped>
.supp_button:hover {
  background-image: url('@/assets/img/menu/z105.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.supp_button a:hover {
  color: #FFFFFF;
}

.supp_button {
  width: 20%;
  height: 48px;
  background-image: url('@/assets/img/menu/z63.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  margin: 10px;
}

.supp_button a {
  width: 100%;
  height: 100%;
  color: #2AB681;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
@media (max-width: 768px) {
  .supp_button {
    width: 48%;
    height: 48px;
    background-image: url('@/assets/img/menu/z63.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    margin: 1%;
  }
}
</style>