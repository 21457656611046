const err_dist = {
    "1000000": "账号不存在",
    "1000001": "账号密码错误",
    "1000002": "验证码错误",
    "1000003": "注册失败,帐号已存在",
    "1100000": "注册邮箱无效(同一个邮箱最多注册5次)",
    "1100001": "您点击的太快了，请稍后再试！",
    "1100002": "发送验证码失败",
    "1200000": "重置密码错误",
    "1200001": "重置密码的验证码无效",
    "1200002": "重置密码次数过多,请等5分钟之后再试",
    "1200003": "重置密码失败",
    "1200004": "重置密码的邮箱无效",
    "1300000": "该账号已有绑定邮箱",
    "1300001": "邮箱验证失败",
    "1400000": "投票时间还没到",
    "2000000": "未登录",
    "2000001": "登录状态错误",
    "2000002": "登录状态错误",
    "2000003": "登录状态错误",
    "3000000": "设备型号不正确",
    "4000001": "创建支付失败",
    "4000002": "选择的支付方式目前不可用",
    "4000003": "选择的支付方式不存在",
    "1310000": "换绑失败(账号没绑定邮箱, 新邮箱非法, 新邮箱和老邮箱相同)",
    "1310001": "换绑失败, 新邮箱已经被绑定超过5次"
}
export function get_err_msg(err_code){
    // console.log(err_code);
    return err_dist[err_code]
}