<template>
  <nav id="nav_bar">
    <div class="nav_bar_logo">
      <img src="@/assets/img/nav/leaf.png" alt="">
      <router-link to="/"><h1>WingsMS</h1></router-link>
    </div>
    <div class="nav_bar_item">
      <router-link to="/">首页</router-link>
    </div>
    <div class="nav_bar_item">
      <router-link to="/download">下载</router-link>
    </div>
    <div class="nav_bar_item">
      <router-link to="/register">注册</router-link>
    </div>
    <div class="nav_bar_item">
      <router-link to="/rank">排行</router-link>
    </div>
    <div class="nav_bar_item">
      <router-link to="/vote">投票</router-link>
    </div>
    <div class="nav_bar_item">
      <router-link to="/support">赞助</router-link>
    </div>
    <div class="nav_bar_item2">
      <div>
        <img src="@/assets/img/nav/user.png" alt="" @click="show_login">
      </div>
    </div>
    <div class="nav_bar_item2">
      <div>
        <a href="https://kook.top/m1VDa1"><img src="@/assets/img/nav/kook.png" alt=""></a>
      </div>
    </div>
  </nav>
  <Login v-if="is_show_login" v-bind:is-show-login="is_show_login"
         @update:is-show-login="updateIsShowLogin"></Login>
</template>

<script>
import Login from "@/components/common/Login";

export default {
  name: "nav_bar",
  components: {
    Login
  },
  data() {
    return {
      // is_show_login: false
      is_show_login: true
    }
  },
  methods: {
    show_login() {
      this.is_show_login = !this.is_show_login
    },
    updateIsShowLogin(value) {
      this.is_show_login = value;
    }
  }
}
</script>

<style scoped>
#nav_bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: white;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.nav_bar_logo {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center; /* 垂直居中 */
}

.nav_bar_item {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.nav_bar_item2 {
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center; /* 垂直居中 */
  position: relative;
}

.nav_bar_item2 img {
  cursor: pointer;
}


nav a.router-link-exact-active {
  /*color: #42b983;*/
  color: #2AB681;
}
@media (max-width: 768px) {
  #nav_bar{
    /*width: 98%;*/
  }
  .nav_bar_logo{
    display: none;
  }
}
</style>