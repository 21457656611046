<template>
  <menu_div></menu_div>
  <div id="div_mask"></div>
  <div id="div_reg">
    <h3>绑定邮箱</h3>
    <div>
      <ul>
        <li>
          <label for="inputEmail">邮箱：
            <span v-if="emailFormatError" style="color: red;">{{ emailFormatError }}</span>
            <span v-else style="color: green;">{{ emailFormat }}</span>
          </label>
          <input v-model="email1" @input="checkEmailFormat" type="text" autocomplete="off" placeholder="请输入您的邮箱(仅支持QQ邮箱和Gmail邮箱)"
                 id="inputEmail">
        </li>
        <li>
          <label for="inputEmail2">确认邮箱：
            <span v-if="emailFormatError" style="color: red;">{{ emailFormatError }}</span>
            <span v-else style="color: green;">{{ emailFormat }}</span>
          </label>
          <input v-model="email2" @input="checkEmailFormat" type="text" id="inputEmail2" autocomplete="off"
                 placeholder="请再次输入您的邮箱">
        </li>
        <li>
          <small>请牢记并保管好您的邮箱，如果您忘记了密码，可以通过邮箱来重置密码。<br>
            <span v-if="emaiMatch" style="color: red;">{{ emaiMatch }}</span>
          </small>
        </li>
      </ul>
    </div>
    <div class="reg_button">
      <supp_button @click="checkEmailMatch" :class="{disabled:isDisabled}">获取验证码</supp_button>
    </div>
    <div>
      <ul>
        <li>
          <label for="code">验证码:
            <span v-if="codeFormat" style="color: red;">{{ codeFormat }}</span>
          </label>
          <input v-model="code" @input="checkCode" type="text" id="code" autocomplete="off" placeholder="请输入您邮箱收到的验证码">
        </li>
        <li>
          <span v-if="passMatch" style="color: red;">{{ passMatch }}</span>
        </li>
      </ul>
    </div>
    <div class="reg_button">
      <supp_button @click="bindEmail" :class="{disabled:regDisabled}">绑定邮箱</supp_button>
    </div>
  </div>
</template>

<script>
import menu_div from "@/components/common/menu_div.vue";
import supp_button from "@/components/common/supp_button.vue";
import {bind_code, bind_mail} from "@/network/bindmail";
import {get_err_msg} from "@/network/errMsg";
export default {
  name: "BindMail",
  components: {
    menu_div,
    supp_button,
  },
  data() {
    return {
      //邮箱获取验证码
      emailFormatError: '',
      emailFormat: '',
      email1: '',
      email2: '',
      emaiMatch: '',
      //注册用户
      code: '',
      inputUser: '',
      inputPass: '',
      inputConfirm: '',
      passMatch: '',
      //验证码，用户，密码格式检测
      codeFormat: '',
      inputUserFormat: '',
      inputPassFormat: '',
      //点击变为灰色不可用
      isDisabled: false,
      regDisabled: false
    }
  },
  methods:{
    //验证邮箱
    checkEmailFormat(event) {
      const input_email = event.target.value.trim()
      // const emailRegex = /^\w+([-+.]\w+)*@(qq|gmail\.com)/
      const emailRegex = /^\w[-\w.+]*@(qq|gmail)\.com$/
      if (!emailRegex.test(input_email)) {
        this.emailFormat = ""
        this.emailFormatError = "邮箱格式错误"
      } else {
        this.emailFormatError = ""
        this.emailFormat = "邮箱格式正确"
      }
    },
    checkEmailMatch() {
      this.isDisabled = true
      setTimeout(() => {
        this.disabled = false
      }, 60000)
      if (this.email1 === "" || this.email2 === "") {
        this.emaiMatch = "邮箱不能为空"
      } else if (this.email1 === this.email2) {
        bind_code(this.email1).then(res => {
          // console.log(res);
          this.emaiMatch = "邮件已发送，请到邮箱查收邮件"
        }, err => {
          this.emaiMatch = "err.message"
        })
      } else {
        this.emaiMatch = "两次输入的邮箱不相同"
      }
    },
    //验证验证码
    checkCode(event) {
      const code = event.target.value.trim()
      const codeRegex = /^[A-Za-z0-9]{1,6}$/
      if (!codeRegex.test(code)) {
        this.codeFormat = "验证码不能超过6位"
      } else {
        this.codeFormat = ""
      }
    },
    //注册用户
    bindEmail() {
      this.regDisabled = true
      if (this.code === "" || this.email1==="") {
        this.passMatch = "输入不能为空"
      } else {
        bind_mail(this.email1, this.code).then(res => {
          // console.log(res);
          if('success' in res){
            this.passMatch = "绑定成功"
            window.sessionStorage.setItem('email', this.email1)
            setTimeout(() => {
              this.$router.push('/')
            }, 3000)
          }
          else {
            this.passMatch = get_err_msg(res.data.errCode)
          }
        })
      }
    },
  }
}
</script>

<style scoped>

</style>