<template>
  <nav_bar ref="nav_login"></nav_bar>
  <div @click="handleOutside">
    <div class="content">
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
    <foot_div></foot_div>
  </div>

</template>


<script>
import nav_bar from "@/components/common/nav_bar";
import foot_div from "@/components/common/foot_div";
export default {
  components:{
    nav_bar,
    foot_div
  },
  methods:{
    handleOutside(){
      this.$refs.nav_login.updateIsShowLogin(false)
    }
  }
}
</script>
<style>
@import "@/assets/css/base.css";


.content{
  width: 60%;
  margin: 80px auto 0;
}
@media (max-width: 768px) {
  .content{
    width: 90%;
    margin: 80px auto 0;
  }
}



</style>
