<template>
  <div class="home">
    <menu_div></menu_div>
  </div>
  <div class="content">
    <h2>游戏特色&原创内容</h2>
  </div>
  <div class="menu_content">
    <div class="menu_ul">
      <div class="spec">
        <img src="../../assets/img/home/family.png" alt="">
        <h3>更具吸引力的家族系统</h3>
      </div>
      <p>
        Wings改变了家族的创建和升级方式，加入了BOSS信物概念。同时随着家族常驻技能的加入，家族的功能性被增强了。家族不再仅仅是一个聊天的群组，建设它将带给玩家更多的成就感和实际的提升。</p>
    </div>
    <div class="menu_ul">
      <div class="spec">
        <img src="../../assets/img/home/plug.png" alt="">
        <h3>游戏插件和使用功能</h3>
      </div>
      <p>
        技术研发是提高游戏体验的重要方式，目前已实装"掉线不清除BUFF"、"防卡功能（屏蔽发包）"、"实时经验统计"，同时实现了客户端的自动更新，我们将持续开发更多实用功能。</p>
    </div>
    <div class="menu_ul">
      <div class="spec">
        <img src="../../assets/img/home/support.png" alt="">
        <h3>赞助的机制与公平性</h3>
      </div>
      <p>
        赞助点券在前期只能购买外观类物品和功能性道具。我们将在扎昆前推出百宝箱，百宝箱的所有物品将不会<strong>独占</strong>，这意味着每一件在百宝箱内的物品都在野外有掉落方式。它将为物价系统的合理稳定起到关键的作用。
      </p>
    </div>
    <div class="menu_ul">
      <div class="spec">
        <img src="../../assets/img/home/world.png" alt="">
        <h3>渐变开放与世界等级</h3>
      </div>
      <p>
        WingsMS是一个基于渐变式内容的怀旧服务器，为了保证进度开放的合理、透明性，我们将控制权交给玩家,引入了"世界等级"机制，它将由活跃角色的加权平均等级决定。随着世界等级的提升，相关的游戏机制如三转、四转等将会自动解锁。</p>
    </div>
    <div class="menu_ul">
      <div class="spec">
        <img src="../../assets/img/home/server.png" alt="">
        <h3>服务器的稳定性概述</h3>
      </div>
      <p>
        WingsMS采用了成熟的安全解决方案，从服务器到赞助渠道都进行了严格的筛选，虽然这确实会为赞助渠道增加门槛。我们的团队分布在海外各地，可以为玩家提供7*24小时的服务。</p>
    </div>
  </div>
</template>

<script>
import menu_div from "@/components/common/menu_div";

export default {
  name: "Home",
  components: {
    menu_div
  }
}
</script>

<style scoped>
/*.content2 {*/
/*  margin: 50px auto 0;*/
/*}*/

.menu_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* 水平居中 */
  padding: 1%;
}

.menu_ul {
  flex-basis: 49%;
}

.menu_ul h3 {
  font-weight: 600;
  color: #494F51;
  line-height: 36px;
  margin-left: 6px;
}
.menu_ul img{
  max-width: 36px;
}
.spec{
  display: flex;
  align-items: center;
}

.menu_ul p {
  font-size: 14px;
  /*font-family: PingFang SC-Regular, PingFang SC;*/
  font-weight: 400;
  color: #919CA8;
  line-height: 36px;
  margin-top: 10px;
}


.content h2 {
  font-weight: 800;
  color: #000000;
  text-align: center;
}
@media (max-width: 768px) {
  .menu_ul {
    flex-basis: 99%;
  }
}
</style>