import {request} from "@/network/request";
// import qs from 'qs'

export function user_vote(userName){
    // console.log(userName);
    return request({
        method:'get',
        url:'/vote/check?name=' + userName,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })
}

