import {request} from "@/network/request";
// import qs from 'qs'

export function get_wood_level(){
    return request({
        method:'get',
        url:'https://wingstory.net/worldLevel',
    })
}

export function get_game_data(index, count, rank_type){
    return request({
        method:'get',
        url:'player/ranks?index=' + index + '&count=' + count + '&type=' + rank_type,
    })
}
export function get_char_data(charName){
    // console.log(charName);
    return request({
        method:'get',
        url:'/player/ranks/index?name='+charName+'&type=6'
    })
}