<template>
  <comm_vote></comm_vote>
  <div id="vote_tab">
    <h2>每个账号每天可以投票一次</h2>
    <p>您初次投票会获得200投票点券，连续投票每次增加50点券，最多增加至每天350点券。如果您的浏览器开启了Adblock插件，可能会影响投票，建议您在投票前先禁用它。</p>
    <p>Step1.输入游戏账号点击下方投票按钮<br>
      Step2.待跳转至Gtop页面，勾选验证选项点击开始答题/验证。<br>
      Step3.根据要求通过人机验证后点击Vote for WingsMS字样红色按钮完成投票。<br>
      Tips:（<strong>GTOP为海外的网站，链接至Gtop可能存在延迟,注意一个IP同一个时间段内只能给一个帐号投票</strong>），如有页面未刷新或投票后点券发放延迟请稍作等待。</p>
    <p>
      <label for="pingUser">
        <span v-if="pingUserDate" style="color: red;">{{ pingUserDate }}</span>
      </label>
      <input v-model="pingUser" type="text" id="pingUser" placeholder="请输入您的账号" >
    </p>
    <supp_button @click="checkPingUser">投票</supp_button>
  </div>
</template>

<script>
import supp_button from "@/components/common/supp_button";
import comm_vote from "@/components/common/comm_vote";
import {user_vote} from "@/network/vote";
import {formatDate} from "@/network/dateFormat"
export default {
  name: "Vote",
  components:{
    supp_button,
    comm_vote
  },
  data(){
    return{
      pingUser:'',
      pingUserDate:''
    }
  },
  methods:{
    checkPingUser(){
      user_vote(this.pingUser).then(res=>{
        if(res.success){
          window.open('https://gtop100.com/topsites/MapleStory/sitedetails/WingsMS-101352?vote=1&pingUsername=' + this.pingUser, '_black')
        }
        else {
          const timeRet=parseInt(res.data.errMsg);
          if (!isNaN(timeRet)){
            const timeEnd=formatDate(timeRet)
            this.pingUserDate="您下次可投票的时间："+timeEnd
          }
          else {
            this.pingUserDate="您输入的帐号不存在"
          }
        }
      })
    }
  }
}
</script>

<style scoped>

#vote_tab{
  margin-top: 60px;
  text-align: center;
  width: 100%;
}

#vote_tab p{
  width: 70%;
  margin: 0 auto;
  text-align: center;
  line-height: 30px;
}
#vote_tab p input{
  margin: 0 0 20px 0;
  background: #FFFFFF;
  border: 1px solid #CFCFCF;
  border-radius: 12px;
  font-size: 14px;
  /*font-family: PingFang SC;*/
  font-weight: 400;
  color: #999999;
  height: 42px;
  width: 60%;
}

#vote_tab label {
  width: 100%;
  margin-top: 10px;
  display: inline-block;
}
@media (max-width: 768px) {
  #vote_tab{
    margin-top: 100px;
  }
  #vote_tab p{
    width: 90%;
  }
  #vote_tab p input{
    width: 80%;
  }
  #vote_tab li{
    width: 60%;
  }
}
</style>