import {request} from "@/network/request";
import qs from 'qs'

export function bind_code(inputEmail){
    // console.log(inputEmail);
    return request({
        method:'post',
        url:'/user/email/code',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: qs.stringify({
            email: inputEmail
        })
    })
}

export function bind_mail(inputEmail,code){
    // console.log(inputEmail);
    return request({
        method:'post',
        url:'/user/email/bind',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: qs.stringify({
            email: inputEmail,
            code: code
        })
    })
}