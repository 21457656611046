<template>
  <div class="rank_ul">
    <div>
      <ul>
        <li>
          <h2>世界等级</h2>
          <p>世界等级=前60%活跃角色数量的平均等级</p>
          <p>
            活跃玩家=近21天有过登录行为，且等级高于70以上的玩家。所有相同邮箱下的账号，只取其中等级最高的角色统计</p>
        </li>
        <li>
          <h2>
            <label for="world_level">{{ wood_level }}</label>
            <input type="text" id="world_level" name="world_level" style="display: none">
          </h2>
        </li>
        <br>
        <li>
          <span>世界等级每小时更新一次</span>
        </li>
      </ul>
    </div>
    <div id="rank_img">
      <ul>
        <img src="@/assets/img/rank/rankpig.png" alt="">
      </ul>
    </div>
  </div>
  <div class="rank_nav">
    <ul class="rank_button">
      <li>
        <img @click="getJobData(1)" src="@/assets/img/rank/warrior.png" title="战士" alt="">
        <img @click="getJobData(2)" src="@/assets/img/rank/mage.png" title="法师" alt="">
        <img @click="getJobData(3)" src="@/assets/img/rank/bow.png" title="游侠" alt="">
        <img @click="getJobData(4)" src="@/assets/img/rank/fly.png" title="飞侠" alt="">
        <img @click="getJobData(5)" src="@/assets/img/rank/pirate.png" title="海盗" alt="">
        <img @click="getJobData(6)" src="@/assets/img/rank/all.png" title="所有" alt="">
      </li>
    </ul>
    <ul class="rank_button">
      <li>
        <label>
          <input v-model="charName" type="text" id="rank_char" placeholder="角色查询" onclick="focus();select()">
          <input @click="getCharData" type="submit" value="GO!" id="rank_char_sub">
        </label>
      </li>

    </ul>
    <ul class="rank_button align-right">
      <ul>
        <li>
          <label for="rank_go1"><input v-model="goPageNum" type="number" placeholder="跳转到……"></label>
          <input @click="subGoPage" type="submit" value="GO!" id="rank_go1">
        </li>
      </ul>
      <ul>
        <li>
          <button @click="prevPage" :disabled="currentPage ===1">&lt;&lt;</button>
        </li>
        <li v-for="item in paginatedData" :key="item" :class="{active:item===nowPage}" @click="changeNowPage(item)">
          <button>{{ item }}</button>
        </li>
        <li>
          <button @click="nextPage" :disabled="currentPage === totalPage">>></button>
        </li>
      </ul>
    </ul>

  </div>

  <table style="width: 100%" id="rank_list">
    <tr>
      <th>排名</th>
      <th>角色</th>
      <th>职业</th>
      <th>等级</th>
      <th>任务数</th>
      <th>怪物卡</th>
    </tr>
    <tr v-for="item in playser_data" :key="item.index">
      <td>{{ item.index + 1 }}</td>
      <td>
        <div><img :src="item.imageUrl" alt=""></div>
        <div>{{ item.name }}</div>
        <div>{{item.guildName}}</div>
      </td>
      <td>{{ item.job }}</td>
      <td>{{ item.level }}</td>
      <td>{{ item.quests }}</td>
      <td>{{ item.monsterCards }}</td>
    </tr>
  </table>
  <div class="rank_foot_nav">
    <ul class="rank_button_foot">
      <li class="phone">
        <label for="rank_go2"><input v-model="goPageNum" type="number" placeholder="跳转到……"></label>
        <input @click="subGoPage" type="submit" value="GO!" id="rank_go2">
      </li>
      <li>
        <button @click="prevPage" :disabled="currentPage ===1">&lt;&lt;</button>
      </li>
      <li v-for="item in paginatedData" :key="item" :class="{active:item===nowPage}" @click="changeNowPage(item)">
        <button>{{ item }}</button>
      </li>
      <li>
        <button @click="nextPage" :disabled="currentPage === totalPage">>></button>
      </li>
    </ul>
  </div>

</template>

<script>
import {get_char_data, get_game_data, get_wood_level} from "@/network/rank";

export default {
  name: "Rank",
  data() {
    return {
      wood_level: 'Loading……',
      data: [],
      playser_data: [],
      currentPage: 1,
      pageSize: 5,
      totalItem: 0,
      totalPage: 100,
      nowPage: 1,
      index: 0,
      count: 19,
      count_num: 0,
      rank_type: 6,
      goPageNum: null,
      charName: ''
      // jobData:[
      //   {url:"@/assets/img/rank/warrior.png",title:"战士"},
      //   {url:"@/assets/img/rank/mage.png",title:"法师"},
      //   {url:"@/assets/img/rank/bow.png",title:"游侠"},
      //   {url:"@/assets/img/rank/fly.png",title:"飞侠"},
      //   {url:"@/assets/img/rank/pirate.png",title:"海盗"},
      //   {url:"@/assets/img/rank/all.png",title:"所有"}
      // ]
    }
  },
  mounted() {
    setTimeout(() => {
      this.count_num = this.count + 1
      this.show_wood_level()
      this.game_data(this.index, this.count, this.rank_type)
    }, 100)
  },
  methods: {
    show_wood_level() {
      get_wood_level().then(res => {
        this.wood_level = res.level + "级"
      })
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--
        this.nowPage--
        this.index = (this.nowPage - 1) * this.count_num
        this.game_data(this.index, this.count, this.rank_type)
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPage) {
        this.currentPage++;
        this.nowPage++
        this.index = (this.nowPage - 1) * this.count_num
        this.game_data(this.index, this.count, this.rank_type)
      }
    },
    changeNowPage(index) {
      this.nowPage = index
      this.currentPage = index
      this.index = (index - 1) * this.count_num
      this.game_data(this.index, this.count, this.rank_type)
    },
    game_data(index, count, rank_type) {
      get_game_data(index, count, rank_type).then(res => {
        // console.log(res);
        if (res.total) {
          this.totalPage = res.total
        }
        this.playser_data = res.data
      })
    },
    subGoPage() {
      // console.log(this.goPageNum);
      // console.log(typeof this.goPageNum);
      if (this.goPageNum > 1 && this.goPageNum < this.totalPage) {
        this.index = (this.goPageNum - 1) * this.count_num
        this.currentPage = this.goPageNum - 1
        this.nowPage = this.currentPage
        this.game_data(this.index, this.count, this.rank_type)
      } else {
        this.index = 0
        this.currentPage = 1
        this.nowPage = this.currentPage
        this.game_data(this.index, this.count, this.rank_type)
      }
    },
    getJobData(index) {
      this.rank_type = index
      this.game_data(this.index, this.count, this.rank_type)
    },
    getCharData() {
      // console.log(this.charName);
      get_char_data(this.charName).then(res => {
        if (res.index) {
          const charIndex = parseInt(res.index)
          // console.log(typeof charIndex);
          this.nowPage = Math.floor(charIndex / this.count) + 1
          this.currentPage = this.nowPage
          // console.log(this.nowPage);
          this.rank_type = 6
          get_game_data(res.index, this.count, this.rank_type).then(res => {
            // console.log(res);
            if (res.total) {
              this.totalPage = res.total
            }
            this.playser_data = res.data
          })
        } else {
          this.charName = "您查询的角色不存在！"
        }
      })
    }
  },
  computed: {
    paginatedData() {
      // const startIndex = (this.currentPage - 1) * this.pageSize;
      const startIndex = this.currentPage - 1;
      const endIndex = startIndex + this.pageSize;
      const pageNumbers = Array.from({length: endIndex - startIndex}, (_, i) => i + startIndex + 1);
      // console.log(pageNumbers);
      return pageNumbers
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
}
</script>

<style scoped>
li {
  list-style: none;
  display: inline-block;
}

.rank_ul ,.rank_button,.rank_nav{
  display: flex;
  /*justify-content: space-between; !* 水平居中 *!*/
}
.rank_button.align-right{
  margin-left: auto;
}
.rank_foot_nav{
  /*align-items: center;*/
  /*justify-content: space-between; !* 水平居中 *!*/
  text-align: center;
  /*width: 40%;*/
  /*margin: 10px auto;*/
}
.phone{

}


.rank_ul ul {
  padding: 10px;
}

.rank_ul li {
  margin: 30px 0 0 0;
}

.rank_ul li h2 {
  font-weight: 600;
  color: #000000;
}

.rank_ul li p {
  font-size: 14px;
  font-weight: 400;
  color: #5C607E;
  line-height: 44px;
}

#rank_list th, #rank_list1 th {
  text-align: center;
  width: 15%;
  height: 15px;
  font-size: 22px;
  /*font-family: PingFang SC;*/
  font-weight: 500;
  color: #000000;
  line-height: 48px;
  background-color: #C7E7FF;
  opacity: 0.7;
  border-radius: 0;
}

#rank_list td {
  text-align: center;
  width: 15%;
  height: 14px;
  font-size: 14px;
  /*font-family: PingFang SC;*/
  font-weight: 500;
  /*line-height: 48px;*/
  border-bottom: solid 1px #DAE2E5;
}

.rank_ul li h2 label {
  padding: 20px 20px 20px 20px;
  border: 1px solid #DAE2E5;
  font-weight: 500;
  /*font-family: PingFang SC;*/
  font-size: 36px;
}

.rank_ul li h2 span {
  font-weight: 800;
  /*font-family: PingFang SC;*/
  font-size: 16px;
}

.rank_gif span {
  line-height: 20px;

}

/*.rank_gif {*/
/*  text-align: center;*/
/*}*/

.rank_gif img {
  display: block;
  margin: 0 auto;
}

.rank_button,.rank_button_foot {
  padding: 0;
  /*flex: 1;*/
}

.rank_button li ,.rank_button_foot li{
  line-height: 30px;
}

.rank_button li img,.rank_button_foot li img {
  cursor: pointer;
}

.rank_button li input,.rank_button_foot li input {
  border-radius: 6px;
  line-height: 26px;
  margin-top: 2px;
  font-size: 14px;
  /*font-family: PingFang SC;*/
}

.rank_button button ,.rank_button_foot button{
  line-height: 30px;
  border: none;
  margin: 0 2px;
  width: 30px;
  cursor: pointer;
}

.active button {
  /*color: #2AB681;*/
  background-color: #2AB681;
  color: #FFFFFF;
}
@media (max-width: 768px) {
  .rank_ul, .rank_nav,.rank_button {
    flex-direction: column;
  }
  #rank_img {
    display: none;
  }
  .rank_button.align-right{
    margin-left: 0;
  }
  .phone{
    width: 100%;
  }
  .rank_ul li {
    margin: 0 0 0 0;
  }
}
</style>