<template>
  <div>
    <div v-if="is_login" class="login_width">
      <span>欢迎来到Wings：{{ user }}</span>
      <div class="dropdown"></div>
      <span>email：{{ email }}</span>
      <span>赞助点券：{{ cash }}</span>
      <span>投票点券：{{ nx }}</span>
      <span id="userNx_val" style="color: green;">{{ vote_val }}</span>
      <div class="dropdown"></div>
      <span><router-link to="/forgetpass">修改密码</router-link></span>
      <span><router-link to="/changepic">修改二级</router-link></span>
      <span v-if="email"><router-link to="/changeemail">换绑邮箱</router-link></span>
      <span v-else><router-link to="/bindemail">绑定邮箱</router-link></span>
      <div class="dropdown"></div>
      <span @click="logout()" type="submit" style="cursor: pointer">退出</span>
    </div>
    <div v-else class="login_width">
      <input v-model="user_name" type="text" placeholder="用户名">
      <input v-model="passwd" type="password" placeholder="密码">
      <span class="forget_pass" style="color: red">{{ login_err }}</span>
      <input @click="login()" type="submit" value="登录" style="cursor: pointer">
      <div class="dropdown"></div>
      <small class="forget_pass">
        <router-link to="/forgetpass">忘记密码</router-link>
      </small>
    </div>
  </div>
</template>

<script>
import {user_login, user_logout} from "@/network/login";
import {user_vote} from "@/network/vote";
import {formatDate} from "@/network/dateFormat";
import {get_err_msg} from '@/network/errMsg'

export default {
  name: "Login",
  props: {
    is_show_login: Boolean
  },
  data() {
    return {
      user_name: '',
      passwd: '',
      login_err: '',
    }
  },
  methods: {
    updateIsShowLogin(value) {
      // console.log("close login")
      // console.log(value);
      this.$emit("update:is-show-login", value);
    },
    logout() {
      user_logout().then(res => {
        window.sessionStorage.removeItem('user')
        window.sessionStorage.removeItem('email')
        window.sessionStorage.removeItem('nx')
        window.sessionStorage.removeItem('cash')
        window.sessionStorage.removeItem('Authorization')
        // this.updateIsShowLogin(false)
        window.location.reload()
      },)
    },
    login() {
      user_login(this.user_name, this.passwd).then(res => {
        // console.log(res);
        if ('name' in res) {
          window.sessionStorage.setItem('user', res.name)
          if (res.email === '') {
            console.log("未绑定邮箱");
          } else {
            const email = res.email
            const pattern = /(.+)@(.+)/
            const match = email.match(pattern)
            const firstPart = match[1].slice(-3)
            const replaceCount = firstPart.length
            let replaceStr = ''
            for (let i = 0; i < replaceCount; i++) {
              replaceStr += '*'
            }
            const hiddenEmail = email.replace(firstPart, replaceStr)
            window.sessionStorage.setItem('email', hiddenEmail)
          }
          // window.sessionStorage.setItem('email', res.email)
          window.sessionStorage.setItem('nx', res.nx_credit)
          window.sessionStorage.setItem('cash', res.nx_cash)
          //验证用户是否可以投票
          user_vote(res.name).then(res => {
            // console.log(res);
            if (res.success) {
              window.sessionStorage.setItem('vote_val', "可以投票")
            } else {
              const timeRet = parseInt(res.data.errMsg);
              if (!isNaN(timeRet)) {
                const timeEnd = formatDate(timeRet)
                window.sessionStorage.setItem('vote_val', "您下次可投票的时间：" + timeEnd)
              } else {
                window.sessionStorage.setItem('vote_val', "帐号不存在")
              }
            }
          })
          // this.updateIsShowLogin(false)
          window.location.reload();
          this.updateIsShowLogin(true)
        } else {
          this.login_err = get_err_msg(res.data.errCode)
        }
      }, err => {
        console.log(err);
      })
    }
  },
  computed: {
    is_login() {
      return window.sessionStorage.getItem('Authorization')
    },
    user() {
      return window.sessionStorage.getItem('user')
    },
    email() {
      return window.sessionStorage.getItem('email')
    },
    nx() {
      return window.sessionStorage.getItem('nx')
    },
    cash() {
      return window.sessionStorage.getItem('cash')
    },
    vote_val() {
      return window.sessionStorage.getItem('vote_val')
    },
  }
}
</script>

<style scoped>
.login_width {
  padding: 10px;
  width: 20%;
  border-radius: 10px;
  z-index: 2;
  position: absolute;
  /*left: -240%;*/
  /*top: 80px;*/
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /*background-color: white;*/
  background-image: url("@/assets/img/base/mb.png");
  border: 1px solid #CFCFCF;
}

.login_width input {
  margin: 0 0 20px 0;
  padding: 0 0 0 10px;
  background: white;
  border: 1px solid #CFCFCF;
  border-radius: 6px;
  font-size: 14px;
  /*font-family: PingFang SC;*/
  font-weight: 400;
  color: #999999;
  height: 42px;
  width: 100%;
}

.login_width a {
  text-decoration-line: none;
  display: block;
  /*font-family: PingFang SC-Regular, PingFang SC;*/
  color: #424242;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 10px;
}

.login_width span {
  width: 100%;
  display: block;
  line-height: 30px;
  /*font-family: PingFang SC-Regular, PingFang SC;*/
  color: #424242;
  font-size: 16px;
  font-weight: 400;
}

.login_width span a {
  text-decoration-line: none;
  display: block;
  /*font-family: PingFang SC-Regular, PingFang SC;*/
  color: #424242;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  line-height: 30px;
}

.dropdown {
  border: 1px solid #CFCFCF;
}

.forget_pass a {
  font-size: 14px;
  color: #7E777D;
}

@media (max-width: 768px) {
  .login_width {
    padding: 10px;
    width: 80%;
    border-radius: 10px;
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: url("@/assets/img/base/mb.png");
    background-size: cover;
    border: 1px solid #CFCFCF;
  }
}
</style>