import axios from 'axios'
import { store } from '@/store'
import Fingerprint2 from 'fingerprintjs2/dist/fingerprint2.min.js'

//随机获取base_url
// const urlArray=['ssl.xz6hstoq.top','ssl.xz6hstoq.buzz','ssl.wuq4gnte.buzz','ssl.7s92megv.top','ssl.7s92megv.buzz']
// const urlArray=['ssl.xz6hstoq.top','ssl.xz6hstoq.buzz','ssl.wuq4gnte.buzz','ssl.7s92megv.top','ssl.7s92megv.buzz']
function getRandomStringFromArray(urlArray) {
    const randomIndex = Math.floor(Math.random() * urlArray.length);
    return urlArray[randomIndex];
}
// const base_Url=getRandomStringFromArray(urlArray)
const base_Url='wingstory.net'
// console.log(base_Url);
Fingerprint2.get(function(components) {
    const values = components.map(function(component,index) {
        if (index === 0) {
            return component.value.replace(/\bNetType\/\w+\b/, '')
        }
        return component.value
    })
    const SerialNumber = Fingerprint2.x64hash128(values.join(''), 31)
    if(SerialNumber){
        store.commit('setSerialNumber', SerialNumber)
    }
})



export function request(config) {
    //创建实例
    const instance = axios.create({
        // baseURL: 'https://'+base_Url,
        timeout: 5000,
        headers:{
            'Serial-Number': store.state.SerialNumber,
        },
    })
    //请求拦截
    instance.interceptors.request.use(config => {
        const token = window.sessionStorage.getItem('Authorization')
        if (token) {
            config.headers.Authorization = `${token}`
        }
        // config.headers['X-Serial-Number']=SerialNumber
        // console.log(config.headers);
        return config
    }, err => {
        // console.log(err);
        return err.response
    })
    //响应拦截
    instance.interceptors.response.use(response => {
        const token = response.headers['authorization']
        if(token){
            // store.commit('setToken', token)
            // localStorage.setItem('token', token)
            window.sessionStorage.setItem('Authorization', token)
        }
        return response.data
    }, err => {
        // console.log("",err);
        return err.response
    })
    //发送真正的网络请求
    return instance(config)
}