<template>
  <footer class="footer clear_class" id="web_footer">
    <div class="content">
      <div class="footer_space"></div>
      <div class="footer_nav"><img src="@/assets/img/nav/leaf.png" alt=""><a href="https://www.wingstory.net/">WingsMS</a></div>
      <div><p>© 2022 Network - wingsMS. All Rights Reserved</p></div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "foot_div"
}
</script>

<style scoped>
#web_footer {
  background-image: url("@/assets/img/footer/footer.jpg");
  height: 446px;
  width: 100%;
}
#web_footer p {
  font-size: 14px;
  /*font-family: PingFang SC-Regular, PingFang SC;*/
  font-weight: 400;
  color: #FFFFFF;
  width: 100%;
}
.footer_nav{
  display: flex;
  height: 80px;
  /*justify-content: center; !* 水平居中 *!*/
  align-items: center; /* 垂直居中 */
}
.footer_nav a{
  font-size: 28px;
  color: white;
}
.footer_space{
  height: 60px;
  width: 100%;
}
</style>